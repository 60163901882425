import { format } from "date-fns";

export const formatDate = (date: Date, f?: string) => {
  const nonStringDate = typeof date === "string" ? Date.parse(date) : date;
  return format(nonStringDate, f || "PPP")
}

export const getYears = (count?: number) => {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - (count || 20))).fill('').map((v, idx) => now - idx);
}

export const convertDateToLocalDate = (pDate: string): Date => {
  const date = pDate?.includes("/") ? pDate.split("/") : pDate?.split("-");
  
  if (!date) return new Date();
  const [year, month, day] = date.map(
    (val, index) => parseInt(val, 10) - (index === 1 ? 1 : 0)
  );
  return new Date(year, month, day);
};
