import Link from "next/link";
import React from "react";
import IconButton from "../../Buttons/IconButton";
import MenuItem from "../MainMenu/MenuItem/MenuItem";
import { useAuth, logout } from "../../../../context/authContext";
import { useRouter } from "next/router";

const AccountMenu = ( props: { user: boolean }) => {
  const subItemClass = "font-extralight text-base text-black hover:text-red capitalize";
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleMenu = (e) => {
    setIsOpen(!isOpen);
  }

  const router = useRouter();
  const {
    state: { user },
    dispatch: authDispatch,
  } = useAuth();

  const handleSignOutClick = async () => {
    logout(authDispatch).then(() => router.push("/"));
  };

  return (
    <nav className="flex justify-end align-top z-40">
      {user ? (
        <ul className="space-y-2 2xl:space-x-6 bg-white justify-end absolute flex-col lg:flex lg:p-0 lg:flex lg:relative lg:top-0 lg:space-y-0 lg:space-x-4" style={{"top":"0.25rem"}}>
          <IconButton className="md:hidden" onClick={toggleMenu}>
          {
            !isOpen
            ? <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
						</svg>
				}
          </IconButton>
          <MenuItem
            path="/"
            label={`${user.first_name} ${user.last_name}`}
            styleName={`md:block font-bold uppercase text-sm text-red z-40 ${ isOpen ? 'flex-col' : 'hidden' }`}
          >
            {user?.admin && (
              <div className={subItemClass}>
                <Link href={"/admin/articles"}>
                  <button>Admin Dashboard</button>
                </Link>
              </div>
            )}
            <div className={subItemClass}>
              <Link href={"/users/edit"}>
                <button>Edit Account</button>
              </Link>
            </div>
            <div className={subItemClass}>
              <button onClick={() => handleSignOutClick()}>Sign Out</button>
            </div>
          </MenuItem>
        </ul>
      ) : (
        <ul className={`flex w-full justify-end align-top space-x-4 lg:pb-2`}>
          <Link href="/users/sign_in">
            <IconButton className="md:hidden" onClick={() => {}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </IconButton>
          </Link>
          <MenuItem
            path="/users/plan"
            label="Subscribe"
            styleName="hidden md:block font-bold uppercase text-sm"
          ></MenuItem>
          <MenuItem
            path="/users/sign_in"
            label="Sign In"
            styleName="hidden md:block font-bold uppercase text-sm text-red"
          ></MenuItem>
        </ul>
      )}
    </nav>
  );
};

export default AccountMenu;
